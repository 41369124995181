.message {
  color: red;
  width: 40%;
  display: flex;
  justify-content: center;
  /* outline: 2px solid yellow; */
}
.message_h {
  width: 40%;
  display: flex;
  justify-content: center;
  /* outline: 2px solid yellow; */
}
.outer {
  /* outline: 2px black solid; */
  /* background-color: white; */
  border-radius: 40px;
}
.button {
  margin-top: 20px;
  display: flex;
  /* outline: 2px solid pink; */
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  margin: 50px auto;
}
.in_button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid blue;
  border-radius: 20px;
  margin: 5px;
  cursor: pointer;
}
.heading {
  /* outline: 2px solid green; */
  height: 50px;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}
.content {
  /* outline: 2px solid yellow; */
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}
.inner_o {
  width: 80%;
  margin: auto;
  /* outline: 2px red solid; */
  border-radius: 30px;
  background-color: white;
}
.inp {
  outline: 2px solid green;
  width: 50%;
  margin: auto;
  position: absolute;
  background-color: white;
  opacity: 0.9;
}
.group_main {
  background-color: white;
  width: 95%;
  /* outline: 1px solid black; */
  margin: 20px auto;
  box-shadow: 10px 10px 11px 8px rgba(225, 205, 205, 0.75);
  box-sizing: border-box;
  padding: 20px;
  border-radius: 20px;
  z-index: -1;
}
.group_flex {
  width: 90%;
  /* outline: 2px red solid; */
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
}
.group_text {
  width: 50%;
  cursor: pointer;
  /* outline: 1px solid black; */
}
.modalBackground {
  width: 100vw;
  height: 100vh;
  opacity: 0.95;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modalContainer {
  width: 653px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  z-index: 1;
  border-radius: 20px;
  overflow: auto;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.sui_button {
  /* outline: 1px solid blue; */
  display: flex;
  justify-content: space-evenly;
}
.container_calendar {
  position: relative;
  /* outline: 1px solid yellow; */
}
.dropdown {
  /* outline: 1px solid red; */
  padding: 30px;
}
.DatePicker {
  z-index: 999;
  height: 40px;
  border: 1px solid grey;
  box-shadow: 0px 0px 2px;
  width: 140px;
  font-size: 16px;
  color: rgb(52, 71, 103);
  opacity: 1;
  font-weight: 700;
  padding: 10px;
  cursor: pointer;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.mapped {
  /* outline: 1px solid blue; */
  margin-top: 30px;
  width: 100%;
}
.mapped th{
  min-width:100px !important;
  padding:1rem !important;
}

.mapped td{
  min-width:100px !important;
  padding:1rem !important;
}

.inputboxStyle {
  width: 300px;
  height: 40px;
  border-radius: 10px;
  text-align: center;
  margin-right: 400px;
  display: flex;
  text-align: center;
  padding-left: 20px;
}

.filterbutton {
  width: 100%;
  height: 10px;
  margin-left: 10px;
}

td,
th {
  text-align: center;
  padding: 2px;
  font-size: medium;
}

.datePickerSecond {
  z-index: 999;
  height: 40px;
  width: 300px;
  border: 1px solid grey;
  color: rgb(52, 71, 103);
  width: 140px;
  font-size: 16px;
  opacity: 1;
  font-weight: 700;
  padding: 10px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
/* new css for error logs */
.errorTable {
  width: 100%;
  overflow: auto;
  border: 0;
  border-collapse: collapse;
  margin-top: 1rem;
  max-width: 100%;
}
.errorTable td,
.errorTable th {
  padding: 5px;
}
.errorTable tr {
  overflow-wrap: anywhere;
  padding-left: 10px;
  border-collapse: collapse;

  border-bottom: 0.1px solid black;

}
.errorTable tr:nth-child(1) {
  border: 0;
}
.errorTable th {
  padding-bottom: 20px;
  border-spacing: 20px;
  font-size: 20px;
}
.errorTable td {
  border: 0;
  font-size: 18px;
  min-width: 170px;
}

