.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.secondmainContainerflex {
  display: flex;

  margin: auto;
}

.secondmainContainerflexsmalldiv {
  width: 100px;
  flex-wrap: wrap;
}

.secondmainContainerflexbox {
  display: flex;
  width: 100%;
 gap:4rem;
  align-items: center;
  margin-top:1rem;
  flex:1;
  font-size: 16px;
  
}
.numberBtn2 {
  width: 50px;
  height: 50px;
  border: 1px solid black;
}
.suggestionContainer {
  position: relative;
}
.errorList {
  max-width: 300px;
  max-height: 250px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: auto;
  min-height: 60px;
  padding: 1rem;
  position: absolute;
  z-index: 1;
  border: 1px solid black;
  background-color: white;
  overflow: auto;
  border-radius: 5.1px;
}
.errorList > div {
  cursor: pointer;
}
.submitBtn {
  padding: 0.7rem;
  border-radius: 0.4rem;
  outline: 0;
  border: 0;
  background-color: skyblue;
  font-size: 18px;
  color: white;
  cursor: pointer;
}
.btnContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 0.8rem;
}
.submitBtn:hover {
  background-color: #3e46ff;
}
.dropzonecontainer {
  min-height: 80px;
  width: auto;
  flex-direction: column;
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;
  padding: 0.4rem;
  border-radius: 5px;
}
.imageListContainer {
  display: flex;
  gap: 0.8rem;
  margin-top: 0.6rem;
  flex-wrap: wrap;
  max-width: 100%;
}
.imagePreview {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  clear: both;
  flex: 1;
}
.imagePreview > img {
  width: 100%;
  height: 100%;
  font-size: 12px;
  cursor: pointer;
}
.closeImage {
  font-size: 16px;
  color: red;
  float: right;

  cursor: pointer;
  padding-top: 0.2rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.closeBtn {
  color: white;

  cursor: pointer;
  position: absolute;
  top: 0;
  right: 1rem;
}

.warningMsg {
  color: red;
  margin-bottom: 0.7rem;
}

.studentsTable{
    border-collapse: collapse;
}
.studentsTable th,.studentsTable td{
    padding:5px;
    border-collapse: collapse;
}
.studentsTable tr,.studentsTable tr{

    border-collapse: collapse;
    
}

.secondmainContainerflexbox h3{
    width:188px;
    font-size:18px;
    font-family: montserrat;
    
}

