.DatePicker {
  z-index: 999;
  height: 40px;
  border: 1px solid grey;
  box-shadow: 0px 0px 5px;
  width: 140px;
  font-size: 16px;
  color: rgb(52, 71, 103);
  opacity: 1;
  font-weight: 700;
  padding: 10px;
  cursor: pointer;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.button {
  margin-top: 20px;
  display: flex;
  /* outline: 2px solid pink; */
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  margin: 50px auto;
}

.navbartop {
  width: 100%;
  height: auto;
}

.navbartopfirst {
  width: 100%;
  height: 10vh;
  /* border: 1px solid black; */
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  color: red;
}

.classdetails {
  width: 100%;
  height: auto;
  /* border: 1px solid black; */
  border-radius: 10px;
  /* display: flex;
  justify-content: space-evenly;
  align-items: center; */
}

.classdetailsfirsttop {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.classdetailsfirst {
  height: auto;
  flex-wrap: wrap;
  margin-left: 10px;
  flex: 18%;
  padding: 2%;
  text-align: right;
  align-items: center;
  margin: auto;
}

.teacherName {
  color: green;
}

.loadingdiv_container {
  position: fixed;
  top: 30%;
  left: 40%;
  border: 1px solid grey;
  border-radius: 10px;
  background: white;
}

.loading {
  width: 300px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 20px 10px;
  display: inline-block;
  animation: scaling 2.5s ease-in-out infinite;
}

@keyframes scaling {
  0%,
  100% {
    transform: scale(0.4);
    background: linear-gradient(90deg, #3e74ff 0%, #3e46ff 100%);
  }
  40% {
    transform: scale(0.8);
    background-color: rgb(34, 185, 127);
  }
  50% {
    transform: scale(0.8);
    background-color: #67ff02;
  }
}

.dot:nth-child(0) {
  animation-delay: 0s;
}

.dot:nth-child(1) {
  animation-delay: 0.2s;
}

.dot:nth-child(2) {
  animation-delay: 0.4s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

.dot:nth-child(4) {
  animation-delay: 0.8s;
}

.showDataDiv {
  width: 50%;
  height: 50%;
  border: 1px solid red;
}

.modalBackground {

 
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width:90%;
}
.modalContainer {
  max-width: calc(100% - 3rem);
  margin:auto;
  min-width:100%;
  max-height: 650px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  z-index: 1;
  border-radius: 20px;
  overflow: auto;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modal_heading {
  display: flex;
  justify-content: space-between;
  /* outline: 1px solid brown; */
}

.modal_mapped {
  /* outline: solid red 1px; */

  width: 750px;
  height: auto;
  grid-template-columns: auto auto auto;
  display: grid;
  margin-top: 20px;
}

.modal_inner {
  display: flex;
  align-items: center;
  /* outline: 1px solid black; */
  margin-top: 10px;
}

.imageWidthAdjust {
  width: 120px;
  height: 120px;
}

.imageWidthAdjustsecond {
  width: 170px;
  height: 170px;
}

.otherDetailsofchild {
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.allImagesgridalign {
  display: flex;
}

.allImagesgridaligncolumn {
  flex: 33.33%;
  height: auto;
  padding: 10px;
  margin: 5px;
  background-color: #cccccc;
  text-align: center;
  justify-content: center;
  align-items: center;
}


.emotionsbuttons {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.modal_mappedsecond {
  margin: auto;
}

.mainImageshowdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainImageshow {
  width: 500px;
  height: 400px;
}

.modal_mapped_recording {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: auto;
}

.inputboxStyle {
  width: 300px;
  height: 40px;
  border-radius: 10px;
  text-align: center;
  margin-right: 400px;
  display: flex;
  text-align: center;
  padding-left: 20px;
}

.sortbyTime {
  width: 100px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 10px;
  background: whitesmoke;
  box-shadow: 0px 0px 5px;
}

.DatePickersecond {
  z-index: 999;
  height: 40px;
  width: 250px;
  border: 1px solid grey;
  color: rgb(52, 71, 103);
  width: 140px;
  font-size: 16px;
  opacity: 1;
  font-weight: 700;
  padding: 10px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.mainHeading {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.roomSiddetails {
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 100px;
  overflow: auto;
}

.roomSiddetailsdiv {
  margin: auto;
  text-align: center;
}

.roomSiddetailsss {
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 200px;

  overflow: auto;
}

.secondmainContainer {
  width: 100%;
  height: 500px;
  overflow: auto;
}

.secondmainContainerflex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
}

.secondmainContainerflexsmalldiv {
  width: 100px;
  flex-wrap: wrap;
}

.secondmainContainerflexbox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.numberBtn2{
  width:50px;
  height:50px;
  border:1px solid black;

}
.suggestionContainer{
  position: relative;
}
.errorList{
  max-width:300px;
  max-height:250px;
 width:100%;
 display: flex;
 align-items: flex-start;
 flex-direction: column;
 height:auto;
 min-height:60px;
 padding:1rem;
  position: absolute;
  z-index: 1;
border:1px solid black;
  background-color: white;
  overflow: auto;
  border-radius:5.1px;

}
.errorList > div{
  cursor: pointer;
}
.submitBtn{
  padding:0.7rem;
  border-radius: 0.4rem;
  outline:0;
  border:0;
  background-color: skyblue;
  font-size: 18px;
  color: white;
  cursor: pointer;
  
}
.btnContainer{
  display: flex;
  width:100%;
  justify-content: center;
  margin-top:0.8rem;
}
.submitBtn:hover{
background-color: #3e46ff;
}
.dropzonecontainer
{

  min-height:80px;
  width:auto;
flex-direction: column;
    border: 1px dashed black;
    display: flex;
    justify-content: center;
    align-items: center;
    word-break: break-all;
    padding:0.4rem;
    border-radius: 5px;
}
.imageListContainer{
  display:flex;
  gap:0.8rem;
  margin-top: 0.6rem;
flex-wrap: wrap;
  max-width: 100%;
}
.imagePreview{
  width:100px;
  height:100px;
  max-width:100px;
  max-height:100px;
  clear:both;
  flex:1;
}
.imagePreview > img{
  width:100%;
  height: 100%;
  font-size:12px;
  cursor: pointer;
  
}
.closeImage{
  font-size: 16px;;
  color:red;
  float:right;

  cursor: pointer;
  padding-top:0.2rem;
 

display:flex;
justify-content: center;
align-items: center;
}
.modalImageView{
  z-index:5;
  width:calc(100% - 3rem);
  height:100%;
  margin:auto;
 top:1rem;
  background-color: black;
  position: absolute;
  border-radius: 5px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  
}

.modalImageView > div:nth-child(2){
  max-height:calc(100% - 4rem);

}
.modalImageView > div:nth-child(2) > img{
  max-height: 100%;
  max-width:100%;
}
.closeBtn{
  color:white;
 
  cursor:pointer;
  position: absolute;
  top:0;
  right:1rem;
}

#parentId textarea{
width:100% !important;
}
#imageParentContainer{
  display:flex;
  flex-direction: column;
  align-items: flex-end;
 
}
.warningMsg{
  color:red;
  margin-bottom:0.7rem;
}
.errorLogViewTable tr, .errorLogViewTable th, .errorLogViewTable td {
  border-collapse: collapse;
}
.errorLogViewTable > tr > td{
  padding:0.4rem 1rem;
  width:auto;
  min-width:100px;
  margin:1rem;
  overflow-wrap: anywhere;
}
.errorLogViewTable > tr > th{
 width:auto;
 padding:0.4rem 1rem;
 overflow-wrap: anywhere;
 
}
.errorLogViewTable > tr{
  border-bottom: 1px solid;
  
}
/* video control buttons */

.controlButtonContainer{
  display:flex;
  gap:1rem;
  margin-top:1rem;
 
}
.controlButtonContainer button{
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
}
.controlButtonContainer button:hover{
color:green;
}
.videoContainer{
  display: flex;
  flex-direction: column;
}