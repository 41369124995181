.DatePicker {
    z-index: 999;
    height: 40px;
    border: 1px solid grey;
    box-shadow: 0px 0px 5px;
    width: 140px;
    font-size: 16px;
    color: rgb(52, 71, 103);
    opacity: 1;
    font-weight: 700;
    padding: 10px;
    cursor: pointer;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  
  .button {
    margin-top: 20px;
    display: flex;
    /* outline: 2px solid pink; */
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    margin: 50px auto;
  }
  
  .navbartop {
    width: 100%;
    height: auto;
  }
  
  .navbartopfirst {
    width: 100%;
    height: 10vh;
    /* border: 1px solid black; */
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: left;
    color: red;
  }
  
  .classdetails {
    width: 100%;
    height: auto;
    /* border: 1px solid black; */
    border-radius: 10px;
    /* display: flex;
    justify-content: space-evenly;
    align-items: center; */
  }
  
  .classdetailsfirsttop {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .classdetailsfirst {
    height: auto;
    flex-wrap: wrap;
    margin-left: 10px;
    flex: 18%;
    padding: 2%;
    text-align: right;
    align-items: center;
    margin: auto;
  }
  
  .teacherName {
    color: green;
  }
  
  .loadingdiv_container {
    position: relative;
    top: 30%;
    left: 40%;
    border: 1px solid grey;
    border-radius: 10px;
    background: white;
  }
  
  .loading {
    width: 300px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
  }
  
  .dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 20px 10px;
    display: inline-block;
    animation: scaling 2.5s ease-in-out infinite;
  }
  
  @keyframes scaling {
    0%,
    100% {
      transform: scale(0.4);
      background: linear-gradient(90deg, #3e74ff 0%, #3e46ff 100%);
    }
    40% {
      transform: scale(0.8);
      background-color: rgb(34, 185, 127);
    }
    50% {
      transform: scale(0.8);
      background-color: #67ff02;
    }
  }
  
  .dot:nth-child(0) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  .dot:nth-child(4) {
    animation-delay: 0.8s;
  }
  
  .showDataDiv {
    width: 50%;
    height: 50%;
    border: 1px solid red;
  }
  
  .modalBackground {
    width: calc(100% - 2rem);
  
    opacity: 0.95;
    position: relative;
   margin:1rem;
   margin-top:24px;
   
    z-index: 0;
   
  }
  .modalContainer {
    width: inherit;
    height: inherit;
    border-radius: 12px;
    background-color: white;
  
    
    padding: 25px;
    
    border-radius: 20px;
    overflow: auto;
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .modal_heading {
    display: flex;
    justify-content: space-between;
    /* outline: 1px solid brown; */
  }
  
  .modal_mapped {
    /* outline: solid red 1px; */
  
    width: 100%;
    height: auto;
    grid-template-columns: auto auto auto;
    display: grid;
    margin-top: 20px;
  }
  
  .modal_inner {
    display: flex;
    align-items: center;
    /* outline: 1px solid black; */
    margin-top: 10px;
  }
  
  .imageWidthAdjust {
    width: 120px;
    height: 120px;
  }
  
  .imageWidthAdjustsecond {
    width: 170px;
    height: 170px;
  }
  
  .otherDetailsofchild {
    width: 100px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  
  .allImagesgridalign {
    display: flex;
  }
  
  .allImagesgridaligncolumn {
    flex: 33.33%;
    height: auto;
    padding: 10px;
    margin: 5px;
    background-color: #cccccc;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  

  
  .emotionsbuttons {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .modal_mappedsecond {
    margin: auto;
  }
  
  .mainImageshowdiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mainImageshow {
    width: 500px;
    height: 400px;
  }
  
  .modal_mapped_recording {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin: auto;
  }
  
  .inputboxStyle {
    width: 300px;
    height: 40px;
    border-radius: 10px;
    text-align: center;
    margin-right: 400px;
    display: flex;
    text-align: center;
    padding-left: 20px;
  }
  
  .sortbyTime {
    width: 100px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 10px;
    background: whitesmoke;
    box-shadow: 0px 0px 5px;
  }
  
  .DatePickersecond {
    z-index: 999;
    height: 40px;
    width: 250px;
    border: 1px solid grey;
    color: rgb(52, 71, 103);
    width: 140px;
    font-size: 16px;
    opacity: 1;
    font-weight: 700;
    padding: 10px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  
  .mainHeading {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  .roomSiddetails {
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 100px;
    overflow: auto;
  }
  
  .roomSiddetailsdiv {
    margin: auto;
    text-align: center;
  }
  
  .roomSiddetailsss {
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 200px;
  
    overflow: auto;
  }
  
  .secondmainContainer {
    width: 100%;
  
    overflow: auto;
    font-size:18px;
  }
  
  .secondmainContainerflex {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
  }
  
  .secondmainContainerflexsmalldiv {
    width: 100px;
    flex-wrap: wrap;
  }
  
  .secondmainContainerflexbox {
    display: flex;
    width: 100%;
    gap:1rem;
    align-items: center;
    padding: 10px;
  }
  
  .secondmainContainerflexbox > *{
  width:Calc(50% - 1rem);
  display:flex;
  flex-wrap: wrap;
  word-wrap: break-word;
  display: block;
  }
  .submitBtn{
      width:auto;
      
      padding:0.5rem;
      border-radius:0.3rem;
      font-size:16px;
      border:0;
     cursor:pointer;
     background-color: skyblue;
     color:white
    
  }
  .submitBtn:hover{
      background-color: #3e46ff;
  }
  .secondmainContainerflexbox2 {
    display: flex;
    width: 100%;
    gap:1rem;
    align-items: center;
    padding: 10px;
    justify-content: center;

  }

  .titleFlex{
display:flex;
justify-content: space-evenly;
font-weight: bold;
font-size: 20px;
  }
  .titleFlex > div{
    cursor: pointer;
    background-color: initial;
    padding:0.4rem;
    border-radius: 0.4rem;
  }
  .numberBtn2{
    width:30px;
    height:30px;
    border:1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
  }
  /* wrong message */
  .wrongMessage{
    color:red;
  }
  .mediaRegionContainer{
    display:flex;
    gap:1rem;
    flex-wrap: wrap;
    flex:1;
  }