#daywiseStatusField .MuiFormControl-root > .MuiOutlinedInput-root:nth-child(1){
    padding:0 !important;
   
    justify-content: center;
    }
    #daywiseStatusField .MuiChip-filled {
        padding:0 !important;
        border-radius: 0 ;
        background-color: white ;
        }

        #daywiseStatusField .MuiChip-label{
            padding:0 !important;
           display:flex;
           justify-content: center;
           width:80px;
        }

        #daywiseStatusField .MuiSelect-select{
            padding: 5px 0rem!important;

        }