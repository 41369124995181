.navchart {
  /* outline: 1px solid red; */
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
}
.nav_daily {
  display: flex;
  justify-content: space-between;
  /* outline: 1px solid red; */
  margin-bottom: 50px;
}
.children {
  /* outline: 1px solid red; */
  display: grid;
  grid-template-columns: 45% 45%;
  grid-gap: 50px;
}
