.DatePicker {
  z-index: 999;
  height: 40px;
  border: 1px solid grey;
  box-shadow: 0px 0px 5px;
  width: 140px;
  font-size: 16px;
  color: rgb(52, 71, 103);
  opacity: 1;
  font-weight: 700;
  padding: 10px;
  cursor: pointer;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.button {
  margin-top: 20px;
  display: flex;
  /* outline: 2px solid pink; */
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  margin: 50px auto;
}

.navbartop {
  width: 100%;
  height: auto;
}

.navbartopfirst {
  width: 100%;
  height: 10vh;
  /* border: 1px solid black; */
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  color: red;
}

.classdetails {
  width: 100%;
  height: auto;
  /* border: 1px solid black; */
  border-radius: 10px;
  /* display: flex;
  justify-content: space-evenly;
  align-items: center; */
}

.classdetailsfirsttop {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.classdetailsfirst {
  height: auto;
  flex-wrap: wrap;
  margin-left: 10px;
  flex: 18%;
  padding: 2%;
  text-align: right;
  align-items: center;
  margin: auto;
}

.teacherName {
  color: green;
}

.loadingdiv_container {
  position: fixed;
  top: 30%;
  left: 40%;
  border: 1px solid grey;
  border-radius: 10px;
  background: white;
}

.loading {
  width: 300px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 20px 10px;
  display: inline-block;
  animation: scaling 2.5s ease-in-out infinite;
}

@keyframes scaling {
  0%,
  100% {
    transform: scale(0.4);
    background: linear-gradient(90deg, #3e74ff 0%, #3e46ff 100%);
  }
  40% {
    transform: scale(0.8);
    background-color: rgb(34, 185, 127);
  }
  50% {
    transform: scale(0.8);
    background-color: #67ff02;
  }
}

.dot:nth-child(0) {
  animation-delay: 0s;
}

.dot:nth-child(1) {
  animation-delay: 0.2s;
}

.dot:nth-child(2) {
  animation-delay: 0.4s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

.dot:nth-child(4) {
  animation-delay: 0.8s;
}

.showDataDiv {
  width: 50%;
  height: 50%;
  border: 1px solid red;
}

.modalBackground {
  width: 100vw;
  height: 100vh;
  opacity: 0.95;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.modalContainer {
  width: 800px;
  height: 600px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  z-index: 1;
  border-radius: 20px;
  overflow: scroll;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modal_heading {
  display: flex;
  justify-content: space-between;
  /* outline: 1px solid brown; */
}

.modal_mapped {
  /* outline: solid red 1px; */

  width: 750px;
  height: auto;
  grid-template-columns: auto auto auto;
  display: grid;
  margin-top: 20px;
}

.modal_inner {
  display: flex;
  align-items: center;
  /* outline: 1px solid black; */
  margin-top: 10px;
}

.imageWidthAdjust {
  width: 120px;
  height: 120px;
}

.imageWidthAdjustsecond {
  width: 170px;
  height: 170px;
}

.otherDetailsofchild {
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.allImagesgridalign {
  display: flex;
}

.allImagesgridaligncolumn {
  flex: 33.33%;
  height: auto;
  padding: 10px;
  margin: 5px;
  background-color: #cccccc;
  text-align: center;
  justify-content: center;
  align-items: center;
}

td,
th {
  text-align: center;
  padding: 2px;
  font-size: medium;
 
  overflow-wrap:anywhere;
  
 
}
td{
  vertical-align: middle;
 
}

.emotionsbuttons {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.modal_mappedsecond {
  margin: auto;
}

.mainImageshowdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainImageshow {
  width: 500px;
  height: 400px;
}

.modal_mapped_recording {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: auto;
}

.inputboxStyle {
  width: 300px;
  height: 40px;
  border-radius: 10px;
  text-align: center;
  margin-right: 400px;
  display: flex;
  text-align: center;
  padding-left: 20px;
}

.sortbyTime {
  width: 100px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 10px;
  background: whitesmoke;
  box-shadow: 0px 0px 5px;
}

.DatePickersecond {
  z-index: 999;
  height: 40px;
  width: 250px;
  border: 1px solid grey;
  color: rgb(52, 71, 103);
  width: 140px;
  font-size: 16px;
  opacity: 1;
  font-weight: 700;
  padding: 10px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.mainHeading {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.roomSiddetails {
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 100px;
  overflow: auto;
}

.roomSiddetailsdiv {
  margin: auto;
  text-align: center;
}

.roomSiddetailsss {
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 200px;

  overflow: auto;
}

.secondmainContainer {
  width: 100%;
  height: 500px;
  overflow: auto;
}

.secondmainContainerflex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
}

.secondmainContainerflexsmalldiv {
  width: 100px;
  flex-wrap: wrap;
}

.secondmainContainerflexbox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

/* image blinking css */

.blinkImage {
  -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
  animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
  color: green;
  min-width: 10px;
  min-Height: 10px;
  max-width: 10px;
  max-Height: 10px;
  border-radius: 5px;
  background-color: red;
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Enable,
.disable {
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.Enable {
  color: green;
}

/* btn enabled */
.btn1_container {
  width: 50px;
  height: 24px;
  background-color: white;
  display: flex;
  position: relative;
  border: 1px solid #111;
}
.btn1_container {
  border-radius: 15px;
}
.one {
  position: absolute;
  height: 22px;
  width: 22px;
 
  display: block;
  border-radius: 30px;
  
  background-color: lightgray;
  border:1px solid black;
  transition: all 0.3s ease;
}
.one {
  left: 00px;
  
  
}
.inactive1 {
  transform: translateX(calc(50px - 24px));

  background-color: skyblue;
  border: 1px solid black;
}

.btnContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.bulletContainer,.bulletContainer > li{
  list-style-position: inside;
  margin-left: 0.75em;
  text-align: left; 
}
.rowBorder{
  border-bottom: 1px solid black;;
}
.orangeWhite{
  background-color: orange;
  color:white;
  border-bottom:1px solid black;
}

.colorCodingContainer{
  margin-top:0.8rem;
}
.ColorCodingFlex{
  display:flex;
  gap:1rem;
  justify-content: center;
align-items: center;
}
.ColorCodingFlex > div:nth-child(1){
  width:20px;
  height:20px;
border-radius: 10px;
background-color: red;
display:flex;

}

.listTable tr{
border-bottom: 0.1px solid black;
}
.listTable tr:nth-child(1){
  border-bottom: 2px solid black;
  }

  .classCountContainer{
    width:99%;
    margin:auto;
    display: flex;
    justify-content: space-between;
    margin-top:1rem;
    font-size:18px;
    font-family:sans-serif ;
  }
  .flexCenterNotFound{
    display:flex;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
    font-family: "Monsterrete";
    
  }
  .classCountColor{
    color:darkorchid
  }
  .classCountColor + div{
    color:forestgreen;
  }
  .classCountColor + div + div{
    color:blueviolet;
  }